import React from "react";
import PropTypes from "prop-types";

export function CtaSimpleCentered({
  bgColor,
  header,
  subheader,
  primaryText,
  primaryTo,
  secondaryText,
  secondaryTo,
}) {
  const bg = !bgColor ? "bg-white" : bgColor;
  return (
    <div className={bg}>
      <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          {header}
          <br />
          {subheader}
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <a
              href={primaryTo}
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              {primaryText}
            </a>
          </div>
          <div className="ml-3 inline-flex">
            <a
              href={secondaryTo}
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out"
            >
              {secondaryText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

CtaSimpleCentered.propTypes = {
  bgColor: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  primaryText: PropTypes.string,
  primaryTo: PropTypes.string,
  secondaryText: PropTypes.string,
  secondaryTo: PropTypes.string,
};

export default function CtaSimpleJustified({
  bgColor,
  header,
  subheader,
  primaryText,
  primaryTo,
  secondaryText,
  secondaryTo,
}) {
  const bg = !bgColor ? "bg-gray-50" : bgColor;
  return (
    <div className={bg}>
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          {header}
          <br />
          <span className="text-indigo-600">{subheader}</span>
        </h2>
        <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div className="inline-flex rounded-md shadow">
            <a
              href={primaryTo}
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              {primaryText}
            </a>
          </div>
          <div className="ml-3 inline-flex rounded-md shadow">
            <a
              href={secondaryTo}
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              {secondaryText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

CtaSimpleJustified.propTypes = {
  bgColor: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  primaryText: PropTypes.string,
  primaryTo: PropTypes.string,
  secondaryText: PropTypes.string,
  secondaryTo: PropTypes.string,
};

export function CtaSimpleStacked({
  bgColor,
  header,
  subheader,
  primaryText,
  primaryTo,
  secondaryText,
  secondaryTo,
}) {
  const bg = !bgColor ? "bg-white" : bgColor;

  return (
    <div className={bg}>
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          {header}
          <br />
          <span className="text-indigo-600">{subheader}</span>
        </h2>
        <div className="mt-8 flex">
          <div className="inline-flex rounded-md shadow">
            <a
              href={primaryTo}
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              {primaryText}
            </a>
          </div>
          <div className="ml-3 inline-flex">
            <a
              href={secondaryTo}
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out"
            >
              {secondaryText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

CtaSimpleStacked.propTypes = {
  bgColor: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  primaryText: PropTypes.string,
  primaryTo: PropTypes.string,
  secondaryText: PropTypes.string,
  secondaryTo: PropTypes.string,
};
